import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import firstStart from "@/components/firstPoint/firstStart.vue";
import firstPoint1 from "@/components/firstPoint/firstPoint1.vue";
import firstPoint2 from "@/components/firstPoint/firstPoint2.vue";
import startTimer from "@/components/StartTimer.vue";
import secondStart from "@/components/secondPoint/secondStart.vue";
import secondPoint1 from "@/components/secondPoint/secondPoint1.vue";
import secondPoint2 from "@/components/secondPoint/secondPoint2.vue";
import secondPoint3 from "@/components/secondPoint/secondPoint3.vue";
import finalPage from "@/components/finalPage.vue";
// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'firstStart',
        component: firstStart,
        meta: {
            index: 0,
        },
    },
    {
        path: '/firstPoint1',
        name: 'firstPoint1',
        component: firstPoint1,
        meta: {
            index: 1,
        },
    },
    {
        path: '/firstPoint2',
        name: 'firstPoint2',
        component: firstPoint2,
        meta: {
            index: 2,
        },
    },
    {
        path: '/startTimer',
        name: 'startTimer',
        component: startTimer,
        meta: {
            index: 3,
        },
    },
    {
        path: '/secondStart',
        name: 'secondStart',
        component: secondStart,
        meta: {
            index: 4,
        },
    },
    {
        path: '/secondPoint1',
        name: 'secondPoint1',
        component: secondPoint1,
        meta: {
            index: 5,
        },
    },
    {
        path: '/secondPoint2',
        name: 'secondPoint2',
        component: secondPoint2,
        meta: {
            index: 6,
        },
    },
    {
        path: '/secondPoint3',
        name: 'secondPoint3',
        component: secondPoint3,
        meta: {
            index: 7,
        },
    },
    {
        path: '/finalPage',
        name: 'finalPage',
        component: finalPage,
        meta: {
            index: 8,
        },
    }
]

var router = new VueRouter({
    routes,
    // mode: 'history'
    mode: 'hash'
    //hash
})

export default router;
