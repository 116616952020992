import Vue from 'vue'
import App from './App.vue'
import router from "./router.js"

import Vuex from 'vuex'

Vue.use(Vuex)

import Axios from 'axios'

Vue.prototype.$axios = Axios

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)

// import wx from "weixin-js-sdk"
// Vue.prototype.$wx = wx

import common from "@/common";

Vue.prototype.$common = common

// main.js

import "/public/style/global.css";
import "../rem";

import Progress from 'vant';
import 'vant/lib/index.css';
Vue.use(Progress);

Vue.config.productionTip = false

new Vue({
    router, // 注入到根实例中
    render: h => h(App)
}).$mount('#app')





