<template>
  <div class="bg" @touchmove.prevent>
    <div>
    </div>
    <el-image :style="[{ 'display':!isShowNext?'none':'block'}]" @click="enterNext" class="nextButton"
              :src="require('@/assets/firstPoint/next-button.png')"></el-image>
  </div>
</template>
<script>

export default {
  data() {
    return {
      isPlaying: false,
      isShowNext: false
    };
  },
  created() {
    this.$common.showLoading();
  },
  mounted() {
    this.$common.hideLoading();
    const videoElement = document.getElementById('videoPlay');
    videoElement.style.display = 'block';
    videoElement.style.zIndex = '1';
    videoElement.play();
    const that = this
    videoElement.addEventListener('ended', function() {
      that.isShowNext = true
      // 在视频播放完成时执行的代码
      console.log('视频播放完成！');
    });
  },
  components: {},
  methods: {
    enterNext() {
      this.$common.showLoading();
      const videoElement = document.getElementById('videoPlay');
      videoElement.style.display = 'none';
      // //enter动画时间
      setTimeout(() => {
        console.log("turn next")
        this.$common.hideLoading();
        this.$router.replace({path: '/firstPoint2'});
      }, 1000);
    },
    playOrPause() {
      console.log('click playOrPause')
      const videoElement = this.$refs.myVideo;

      if (videoElement.paused) {
        videoElement.play();
        this.isPlaying = true
      } else {
        videoElement.pause();
        this.isPlaying = false
      }
    },
  },
}
</script>

<style scoped>
.bg {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/firstPoint/bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% auto;
  background-color: black;
  /*z-index: 0;*/
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.nextButton {
  position: absolute;
  width: 4.83rem;
  height: 2.04rem;
  bottom: 2.55rem;
  z-index: 2;
}

.videoButton {
  position: absolute;
  width: 1.98rem;
  height: 1.98rem;
  top: 8.7rem;
  pointer-events: none;
}

</style>
