<template>
  <div class="circle-main">
    <div class="circle-main-box" :style="[{ 'width': size+'px','height': size+'px'}]">
      <el-image :style="[{ 'width': size-40+'px','height': size-40+'px'}]" class="circle-border"
                :src="require('@/assets/circle-bg.png')">
      </el-image>
      <svg :width="size" :height="size" class="circle" style="background-color: rgba(0,0,0,0)">
        <circle
            :r="radius"
            :cx="cx"
            :cy="cy"
            fill="transparent"
            stroke="grey"
            :stroke-width="strokeWidth"
        />
        <circle
            :r="radius"
            :cx="cx"
            :cy="cy"
            fill="transparent"
            :stroke="color"
            :stroke-width="strokeWidth-4"
            stroke-linecap="round"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="progress"
        />
      </svg>
      <div :style="[{ 'width': size+'px','height': size+'px',transform: 'rotate(' + this.rotate + 'deg)'}]"
           class="circle-border">
        <el-image :style="[{ 'width': '30px','height': '30px'}]" class="circle-border-point"
                  :src="require('@/assets/red-point.png')">
        </el-image>
      </div>
      <!--      <span class="count-num" :style="[{ 'font-size': size*.3+'px'}]">{{countDown}}%</span>-->
    </div>
  </div>
</template>
<script>
export default {
  name: "CircleProgress",
  data() {
    return {
      now: 0,
      rotate: 0,
    };
  },
  mounted() {
    this.run();
  },
  props: {
    // 进度值
    value: {
      type: [String, Number],
      default: 0
    },
    // 尺寸
    size: {
      type: [String, Number],
      default: 120
    },
    // 边框粗细
    strokeWidth: {
      type: [String, Number],
      default: 16
    },
    // 进度条颜色
    color: {
      type: String,
      default: "rgba(153,202,251,1)"
    },
    // 动画执行时间
    duration: {
      type: [String, Number],
      default: 1000
    }
  },
  computed: {
    percentage() {
      return this.value;
    },
    countDown() {
      return this.now;
    },
    // 圆心x轴坐标
    cx() {
      return this.size / 2;
    },
    // 圆心y轴坐标
    cy() {
      return this.size / 2;
    },
    // 半径
    radius() {
      return (this.size - this.strokeWidth) / 2;
    },
    // 圆周长
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    // 进度长度
    progress() {
      return (1 - this.now / 100) * this.circumference;
    },
  },
  methods: {
    run() {
      if (this.value == 0) return;
      let t = this.duration / this.value
      this.timer = setInterval(() => {
        if (this.now >= this.value) {
          return clearInterval(this.timer);
        }
        this.now++;
      }, t);
      this.now = this.value
    }
  }
};
</script>

<style lang="css" scoped>
.circle {
  transform: rotate(-90deg);
}

.circle-main-box {
  position: relative;
  display: block;
  margin: 0 auto;
}

.circle-border {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.circle-border-point {
  position: absolute;
  right: 0;
  left: 0;
  top: -8px;
  margin: auto;
}


.count-num {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: fantasy;
  font-size: 30px;
  color: #333;
  user-select: none;
}
</style>
