import ElementUI from "element-ui";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"


let loadingInstance = null;

export default {

    /**
     * 显示加载遮罩
     */
    showLoading() {
        let option = {
            background: 'rgba(0, 0, 0, 0.7)',
        }
        loadingInstance = ElementUI.Loading.service(option);
    },
    /**
     * 关闭加载
     */
    hideLoading() {
        if (loadingInstance != null) {
            loadingInstance.close();
        }
    },
    /**
     * 弹窗信息
     */
    showToast(message) {
        Toastify({
            text: message,
            duration: 3000,
            newWindow: true,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            offset: {
                x: 0, // horizontal axis - can be a number or a string indicating unity. eg: '2em'
                y: 50 // vertical axis - can be a number or a string indicating unity. eg: '2em'
            },
            style: {
                // background: "linear-gradient(to right, #D64F00, #D64F00)",
                textAlign: "center",
                "border-radius": "50px",
                // border: "solid 2px black",
                // "box-shadow": "3px 3px 0 2px gray",
            },
        }).showToast();
    },
    /**
     * 生成 0 到 99 的随机整数
     * @returns {number}
     */
    generateRandomInteger() {
        return Math.floor(Math.random() * 100); // 生成 0 到 99 的随机整数
    }
}
