<template>
  <div class="bg" @touchmove.prevent>
    <div>
      <CircleProgress ref="circleProgress" class="countdown" :size="260" :color="'white'"
                      :duration="0"/>
      <p class="progress">{{ progress }}%</p>
      <div style="width: 7.0rem;text-align: center;margin-top: 9rem">
        <el-image class="success-icon" :src="require('@/assets/secondPoint/success-icon.png')"></el-image>
<!--        <el-image class="qr-code" :src="require('@/assets/secondPoint/qrcode.png')"></el-image>-->
        <p class="final-tip" style="margin-top: 5rem;">感谢参与！</p>
        <p class="final-tip">现场领取精美礼品一份</p>
      </div>
    </div>
  </div>
</template>

<script>
import CircleProgress from "@/components/Page2/circleProgress.vue";

export default {
  data() {
    return {
      startTime: null,
      timerInterval: null,
      elapsedTime: 0,
      progress: 0,
      currentRate: 0,
      circleValue: 0,
      sliderValue: 180,
    }
  },
  computed: {},
  created() {
    this.$common.showLoading();
    console.log(`the StartTimer is now created.`);
  },
  mounted() {
    console.log(`the StartTimer is now mounted.`);
    this.$common.hideLoading();
    this.setValue(100)
    // this.$refs.circleProgress.now = 50
  },
  components: {
    CircleProgress
  },
  methods: {
    setValue(value) {
      this.circleValue = value
      this.timerInterval = setInterval(this.updateTime, 20); // Update every 10 milliseconds
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },
    updateTime() {
      if (this.$refs.circleProgress.now < this.circleValue) {
        this.$refs.circleProgress.now += 1;
        this.$refs.circleProgress.rotate = (this.$refs.circleProgress.now / 100) * 360
        this.progress += 1;
      } else {
        this.$refs.circleProgress.now = this.circleValue;
        this.progress = this.circleValue;
        this.stopTimer();
      }
    },
    formattedTime() {
      const minutes = String(Math.floor(this.elapsedTime / 60)).padStart(2, '0');
      const seconds = String(Math.floor(this.elapsedTime % 60)).padStart(2, '0');
      const milliseconds = String(Math.floor((this.elapsedTime % 1) * 100)).padStart(2, '0');
      return `${minutes}:${seconds}.${milliseconds}`;
    },
  },
}
</script>

<style scoped>
.bg {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/secondPoint/bgFinal.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% auto;
  background-color: black;
  /*z-index: 0;*/
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.success-icon {
  width: 4.05rem;
  height: 1.05rem;
  margin-top: 0.5rem;
}

.qr-code {
  width: 3.21rem;
  height: 3.21rem;
  margin-top: 0.5rem;
}

.final-tip {
  font-size: 0.45rem;
  font-weight: normal;
  color: #C1C1C1;
  line-height: 0.78rem;
  text-align: center;
  //width: 4.15rem;
  height: auto;
}

.progress {
  width: 5.7rem;
  height: 260px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 1.5rem;
  font-size: 1.2rem;
  line-height: 260px;
  text-align: center;
  font-weight: 800;
  color: white;
  vertical-align: center;
}

.countdown {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 1.5rem;
}
</style>
