import { render, staticRenderFns } from "./secondStart.vue?vue&type=template&id=5b206407&scoped=true&"
import script from "./secondStart.vue?vue&type=script&lang=js&"
export * from "./secondStart.vue?vue&type=script&lang=js&"
import style0 from "./secondStart.vue?vue&type=style&index=0&id=5b206407&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b206407",
  null
  
)

export default component.exports