<template>
  <div class="bg" @touchmove.prevent>
    <div id="content">
      <CircleProgress ref="circleProgress" class="countdown" :size="260" :color="'white'"
                      :duration="0"/>
      <p class="progress">{{ progress }}%</p>
      <el-image :style="[{ 'display':!showLabelA?'none':'block'}]" class="success-icon" :src="require('@/assets/firstPoint/first-success.png')"></el-image>
      <el-image :style="[{ 'display':showLabelA?'none':'block'}]" class="success-icon" :src="require('@/assets/firstPoint/second-success.png')"></el-image>
      <el-image class="tip-label" :src="require('@/assets/tip-label.png')"></el-image>
      <el-image class="homeButton" @click="goHome" :src="require('@/assets/home-button.png')"></el-image>
    </div>
  </div>
</template>

<script>
import CircleProgress from "@/components/Page2/circleProgress.vue";

export default {
  data() {
    return {
      startTime: null,
      timerInterval: null,
      elapsedTime: 0,
      progress: 0,
      currentRate: 0,
      circleValue: 0,
      sliderValue: 180,
      showLabelA: false
    }
  },
  computed: {},
  created() {
    this.$common.showLoading();
    console.log(`the StartTimer is now created.`);
  },
  mounted() {
    console.log(`the StartTimer is now mounted.`);
    this.$common.hideLoading();
    this.setValue(50)
    // this.$refs.circleProgress.now = 50
    let a = localStorage.getItem("A");
    if (a != null) {
      this.showLabelA = true
    }

  },
  components: {
    CircleProgress
  },
  methods: {
    setValue(value) {
      this.circleValue = value
      this.timerInterval = setInterval(this.updateTime, 20); // Update every 10 milliseconds
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },
    updateTime() {
      if (this.$refs.circleProgress.now < this.circleValue) {
        this.$refs.circleProgress.now += 1;
        this.$refs.circleProgress.rotate = (this.$refs.circleProgress.now / 100) * 360
        this.progress += 1;
      } else {
        this.$refs.circleProgress.now = this.circleValue;
        this.progress = this.circleValue;
        this.stopTimer();
      }
    },
    formattedTime() {
      const minutes = String(Math.floor(this.elapsedTime / 60)).padStart(2, '0');
      const seconds = String(Math.floor(this.elapsedTime % 60)).padStart(2, '0');
      const milliseconds = String(Math.floor((this.elapsedTime % 1) * 100)).padStart(2, '0');
      return `${minutes}:${seconds}.${milliseconds}`;
    },
    goHome() {
      this.$router.replace({path: '/'});
    }

  },
}
</script>

<style scoped>
.bg {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/firstPoint/bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% auto;
  background-color: black;
  /*z-index: 0;*/
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.success-icon {
  width: 4.05rem;
  height: 1.05rem;
  margin-top: 10.5rem;
}

.tip-label {
  position: absolute;
  width: 3.95rem;
  height: 0.74rem;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 4rem;
}

.homeButton {
  position: absolute;
  width: 5.26rem;
  height: 2.44rem;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 1.5rem;
}

.progress {
  width: 5.7rem;
  height: 260px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 1.5rem;
  font-size: 1.2rem;
  line-height: 260px;
  text-align: center;
  font-weight: 800;
  color: white;
  vertical-align: center;
}

.countdown {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 1.5rem;
}
</style>
