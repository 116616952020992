<template>
  <div class="bg">
    <el-image style="width: 10.8rem;height:70.77rem " :src="require('@/assets/firstPoint/long-pic.jpg')"></el-image>
    <el-image @click="enterNext" class="nextButton"
              :src="require('@/assets/firstPoint/next-button.png')"></el-image>
  </div>
</template>
<script>

export default {
  data() {
    return {};
  },
  created() {
    this.$common.showLoading();
  },
  mounted() {
    this.$common.hideLoading();
  },
  components: {},
  methods: {
    enterNext() {
      this.$common.showLoading();
      setTimeout(() => {
        console.log("turn next")
        this.$common.hideLoading();
        this.$router.replace({path: "/finalPage"});
      }, 1000);
    }
  },
}
</script>

<style scoped>
.bg {
  width: 100vw;
  /*z-index: 0;*/
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.nextButton {
  position: absolute;
  width: 4.83rem;
  height: 2.04rem;
  bottom: 2.0rem;
  z-index: 2;
}


</style>
