<template>
  <div class="bg" @touchmove.prevent>
    <div style="width: 9.2rem">
      <p style="font-size: 0.9rem;font-weight: normal;color: #FFFFFF;line-height: 1rem;margin-top: 0.8rem">第一题</p>
      <p style="font-size: 0.5rem;font-weight: normal;color: #FFFFFF;line-height: 1rem;margin-top: 0.2rem">
        HFNC高流量给氧技术在围术期的应用有哪些？（多选）</p>
      <div class="progress-bar">
        <van-progress :percentage="33" :show-pivot="false" :track-color="'black'" :color="'#9D9D9D'"/>
      </div>
      <div class="item-point1" @click="clickSelectA" style="margin-top: 0.5rem">
        <div class="select-item">
          <el-image class="try-center" :style="[{ 'display':selectA?'none':'block'}]"
                    style="width: 0.4rem;height: 0.45rem;"
                    :src="require('@/assets/secondPoint/A.png')">
          </el-image>
          <el-image :style="[{ 'display':!selectA?'none':'block'}]" class="try-center"
                    style="width: 0.74rem;height: 0.74rem;"
                    :src="require('@/assets/secondPoint/A-select.png')">
          </el-image>
        </div>
        <div class="answer-label">
          <p>在术前诱导过程中，延长患者安全窒息时间，维持充分氧合</p>
        </div>
      </div>
      <div class="item-point1" @click="clickSelectB">
        <div class="select-item">
          <el-image :style="[{ 'display':selectB?'none':'block'}]" class="try-center"
                    style="width: 0.33rem;height: 0.45rem;"
                    :src="require('@/assets/secondPoint/B.png')">
          </el-image>
          <el-image :style="[{ 'display':!selectB?'none':'block'}]" class="try-center"
                    style="width: 0.74rem;height: 0.74rem;"
                    :src="require('@/assets/secondPoint/B-select.png')">
          </el-image>
        </div>
<!--        <div class="answer-label">-->
<!--         -->
<!--        </div>-->
        <span>辅助麻醉医生更轻松地完成插管</span>
      </div>
      <div class="item-point1" @click="clickSelectC">
        <div class="select-item">
          <el-image :style="[{ 'display':selectC?'none':'block'}]" class="try-center"
                    style="width: 0.36rem;height: 0.47rem;"
                    :src="require('@/assets/secondPoint/C.png')">
          </el-image>
          <el-image :style="[{ 'display':!selectC?'none':'block'}]" class="try-center"
                    style="width: 0.74rem;height: 0.74rem;"
                    :src="require('@/assets/secondPoint/C-select.png')">
          </el-image>
        </div>
        <div class="answer-label">
          <p>高流量给氧的使用，可以在紧急情况下和在困难插管过程中减轻医护压力，让工作流程更为平顺，改变紧张的循环启停的操作方式</p>
        </div>
      </div>
      <div class="item-point1" @click="clickSelectD">
        <div class="select-item">
          <el-image :style="[{ 'display':selectD?'none':'block'}]" class="try-center"
                    style="width: 0.37rem;height: 0.45rem;"
                    :src="require('@/assets/secondPoint/D.png')">
          </el-image>
          <el-image :style="[{ 'display':!selectD?'none':'block'}]" class="try-center"
                    style="width: 0.74rem;height: 0.74rem;"
                    :src="require('@/assets/secondPoint/D-select.png')">
          </el-image>
        </div>
        <div class="answer-label">
          <p>高流量给氧可以增加无插管胸腔镜手术期间和术后的患者氧气储备，提高非插管胸腔镜手术的安全性</p>
        </div>
      </div>
    </div>
    <div v-if="showLabel" class="answer">答案：ABCD</div>
    <el-image :style="[{ 'display':showLabel?'none':'block'}]" @click="confirm" class="confirmButton second"
              :src="require('@/assets/secondPoint/confirm.png')"></el-image>
    <el-image :style="[{ 'display':!showLabel?'none':'block'}]" @click="nextStep" class="confirmButton second"
              :src="require('@/assets/secondPoint/next-button.png')"></el-image>
  </div>
</template>
<script>

export default {
  data() {
    return {
      base64DataParams: null,
      selectA: false,
      selectB: false,
      selectC: false,
      selectD: false,
      showLabel: false,
    };
  },
  created() {
    this.$common.showLoading();
  },
  mounted() {
    this.base64DataParams = localStorage.getItem("base64Data");
    this.$common.hideLoading();
  },
  components: {},
  methods: {
    clickSelectA() {
      this.selectA = !this.selectA
    },
    clickSelectB() {
      this.selectB = !this.selectB
    },
    clickSelectC() {
      this.selectC = !this.selectC
    },
    clickSelectD() {
      this.selectD = !this.selectD
    },
    confirm() {
      this.selectA = true;
      this.selectB = true;
      this.selectC = true;
      this.selectD = true;
      this.showLabel = true;
    },
    nextStep() {
      this.$common.showLoading();
      setTimeout(() => {
        console.log("turn next")
        this.$common.hideLoading();
        this.$router.replace({path: '/secondPoint2'});
      }, 1000);
    }
  },
}
</script>

<style scoped>
.bg {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/secondPoint/bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% auto;
  background-color: black;
  /*z-index: 0;*/
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.confirmButton {
  width: 5.26rem;
  height: 2.73rem;
}

.item-point1 {
  margin-top: 0.5rem;
  display: inline-flex;
  //position: relative;
}
.item-point1 span {
  display: block;
  height: 0.74rem;
  font-size: 0.35rem;
  line-height: 0.74rem;
  color: white;
  margin-left: 0.3rem;
}


.answer-label {
  width: 8.3rem;
  margin-left: 0.3rem;
}

.answer-label p {
  display: block;
  color: white;
  line-height: 0.45rem;
  font-size: 0.35rem;
}

.select-item {
  width: 0.74rem;
  height: 0.74rem;
  position: relative;
}

.try-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.second {
  position: absolute;
  bottom: 1.0rem;
}

.answer {
  color: #C51917;
  font-size: 0.55rem;
  position: absolute;
  bottom: 3.6rem;
}

.progress-bar {
  border: #9D9D9D 1px solid;
  border-radius: 50rem;
}

</style>
