<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
// import Axios from "axios";

export default {
  name: 'App',
  data() {
    return {
      transitionName: '',
    };
  },
  computed: {
    isLoginPage: function () {
      console.log("this.$route.name: " + this.$route.name)
      return (this.$route.name !== "Login")
    }
  },
  mounted() {
    console.log("mounted")
  },
  methods: {
    back() {
      this.$router.back(-1)
    },
    goBackInHCZApp() {
    }
  },
  watch: {
    $route(to, from) {
      if (to.meta.index > from.meta.index) {
        console.log('slideleft');
        this.transitionName = 'slide-left';
      } else {
        console.log('slideright');
        this.transitionName = 'slide-right';
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  overflow-x: hidden;
  overflow-y: hidden;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
//-webkit-touch-callout: none; /*系统默认菜单被禁用*/ //-webkit-user-select: none; /*webkit浏览器*/ //user-select: none;
}

input {
  -webkit-user-select: auto; /*webkit浏览器*/
}

.root {
  margin: 16px;
}

.bar {
  height: 56px;
  padding: 8px;
  padding-top: 5%;
}

.shell-color-primary {
  background-color: #FF6300 !important;
  color: #ffffff !important;

}

.md-primary {
  display: inline-block !important;
  width: 100%;
  margin: 0px !important;
}

.shell-color-accent {
  color: #606266 !important;
}

.el-row {
  margin-bottom: 16px;
}

.slide-left-enter-active,
.slide-right-enter-active {
  transition: all 0.6s ease;
}

.slide-left-enter,
.slide-left-leave-to {
  position: absolute;
  left: 0;
  /* top: 56px; */
  right: 0;
  transform: translateX(120%);
  opacity: 1;
}

.slide-right-enter,
.slide-right-leave-to {
  position: absolute;
  left: 0;
  /* top: 56px; */
  right: 0;
  transform: translateX(-120%);
  opacity: 1;
}

.slide-left-leave-active {
  transition: all 0.6s ease;
  transform: translateX(-120%);
  z-index: 100;
}

.slide-right-leave-active {
  transition: all 0.6s ease;
  transform: translateX(120%);
  z-index: 100;
}

.el-step__head.is-success {
  border-color: #ff6600 !important;
}

.el-step__title.is-success {
  color: #ff6600 !important;
}

.el-step__icon-inner.is-status {
  color: #ff6600 !important;
}


</style>

