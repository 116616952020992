<template>
  <div class="bg" @touchmove.prevent>
    <el-image class="title" style="width: 10.8rem;height: 5.8rem;"
              :src="require('@/assets/firstPoint/title.png')"></el-image>
    <el-image @click="enter" class="first" style="width: 4.01rem;height: 1.21rem"
              :src="canClickA?a_enable:a_disable"></el-image>
    <p class="label" style="position:absolute;bottom: 5.6rem">展台体验，2F B10</p>
    <el-image @click="enter2" class="second" style="width: 4.01rem;height: 1.21rem"
              :src="canClickB?b_enable:b_disable"></el-image>
    <p class="label" style="position:absolute;bottom: 2.6rem">学术聆听，1F103会议室</p>
    <p class="label" style="position:absolute;bottom: 2rem">9月22日，12:00-13:00</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      canClickA: true,
      canClickB: true,
      a_enable: require('@/assets/firstPoint/button1.png'),
      a_disable: require('@/assets/secondPoint/button1.png'),
      b_enable: require('@/assets/secondPoint/button2.png'),
      b_disable: require('@/assets/firstPoint/button2.png'),
    };
  },
  created() {
    this.$common.showLoading();
    this.canClickA = false;
    this.canClickB = false;
  },
  mounted() {
    // 第二站二维码】22号11点~14点第二站开启，14点之后可开启第一站第二站
    let currentTime = Date.parse(new Date()) / 1000;
    const timeOpenA = '2023-09-22 14:00:00';

    const timeOpenB1 = '2023-09-22 11:00:00';
    const timeOpenB2 = '2023-09-22 14:00:00';

    let timestampB1 = new Date(timeOpenB1.replace(/-/g, "/")).getTime() / 1000;
    let timestampB2 = new Date(timeOpenB2.replace(/-/g, "/")).getTime() / 1000;
    if (currentTime >= timestampB1 && currentTime <= timestampB2) {
      this.canClickB = true
    }

    let timestampA = new Date(timeOpenA.replace(/-/g, "/")).getTime() / 1000;
    if (currentTime >= timestampA) {
      this.canClickA = true
    }
    this.$common.hideLoading();
  },
  components: {},
  methods: {
    enter() {
      if (!this.canClickA) {
        return;
      }
      this.$common.showLoading();
      setTimeout(() => {
        console.log("turn next")
        this.$common.hideLoading();
        this.$router.replace({path: '/firstPoint1'});
      }, 1000);
    },
    enter2() {
      if (!this.canClickB) {
        return;
      }
      this.$common.showLoading();
      setTimeout(() => {
        console.log("turn next")
        this.$common.hideLoading();
        this.$router.replace({path: '/secondPoint1'});
      }, 1000);
    },
  },
}
</script>

<style scoped>
.bg {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/firstPoint/bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% auto;
  background-color: black;
  /*z-index: 0;*/
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.first {
  position: absolute;
  bottom: 6.4rem;
}

.title {
  position: absolute;
  top: 2rem;
}

.second {
  position: absolute;
  bottom: 3.4rem;
}

.label {
  color: #B8B8B8;
  font-size: 0.4rem;
}

</style>

